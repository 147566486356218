<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" :width="width" :height="height"><g stroke-linecap="square" stroke-linejoin="miter" :stroke-width="strokewidth" :fill="_secondaryfill" :stroke="_secondaryfill"><line x1="2" y1="30" x2="6.001" y2="25.999" fill="none" stroke-miterlimit="10" :stroke="fill" /><line x1="14" y1="14" x2="11" y2="17" fill="none" stroke-miterlimit="10" :stroke="fill" /><line x1="18" y1="18" x2="15" y2="21" fill="none" stroke-miterlimit="10" :stroke="fill" /><path d="M8.169,14.169,6,16.336A6.833,6.833,0,0,0,15.664,26l2.167-2.168Z" fill="none" stroke-miterlimit="10" :stroke="fill" /><line x1="30" y1="2" x2="25.999" y2="6.001" fill="none" stroke-miterlimit="10" /><path d="M23.831,17.831,26,15.664A6.833,6.833,0,0,0,16.336,6L14.169,8.169Z" fill="none" stroke-miterlimit="10" /></g></svg>
</template>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>