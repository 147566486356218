<template>
	<div>
		<AccountMenu />
		<AdminSidebar />

		<slot />

		<NoticeArea />
	</div>
</template>

<script>
	import AccountMenu from '../components/AccountMenu';
	import AdminSidebar from '../components/AdminSidebar';

	export default {
		components: {
			AccountMenu,
			AdminSidebar
		}
	};
</script>

<style lang="scss">
	.admin-layout {
		main {
			margin-left: $sidebar__width;
			transition: margin-left 0.2s ease;
			padding: $site__padding * 2 0;

			& > :not(.responsive-table) {
				padding-left: $site__padding * 2;
				padding-right: $site__padding * 2;
			}

			h2 {
				font-size: 1em;
				line-height: $line__height;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				font-weight: $font__bold;
				margin-bottom: 0.5em;
			}

			p,
			ul,
			ol {
				margin-bottom: $line__height;
			}
		}

		@include breakpoint-min('large') {
			.sidebar.active + main {
				margin-left: $sidebar__width_active;
			}
		}

		ul,
		ol {
			list-style-type: none;
		}

		.notices {
			position: fixed;
			left: calc(#{$sidebar__width_active} + #{$site__padding});
			bottom: $site__padding;
		}

		.input-group {
			margin-top: $default_padding;
			margin-bottom: $default_padding;

			.bool-input {
				margin-right: $default_padding;
			}
		}
	}
</style>