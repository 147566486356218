<template>
	<aside :class="classes">
		<header class="hide-small">
			<h1>{{ tenantName }} Admin</h1>
		</header>

		<span class="menu-button" role="button" aria-label="Toggle menu" @click="toggleMenu">
			<IconMenu :width="32" :stroke="2" />
		</span>

		<nav class="sidebar-content">
			<ul>
				<li>
					<router-link :to="{name: 'AdminCourses'}">
						<span class="highlight hide-small" />
						<IconCourses :width="30" :height="30" />
						<span class="hide-small">Courses</span>
					</router-link>
				</li>
				<li>
					<router-link :to="{name: 'AdminClasses'}">
						<span class="highlight hide-small" />
						<IconStudentHat :width="30" :height="30" />
						<span class="hide-small">Classes</span>
					</router-link>
				</li>
				<li v-if="admin">
					<router-link :to="{name: 'AdminUsers'}">
						<span class="highlight hide-small" />
						<IconUsers :width="30" :height="30" />
						<span class="hide-small">Users</span>
					</router-link>
				</li>
				<li v-if="admin">
					<router-link :to="{name: 'AdminSettings'}">
						<span class="highlight hide-small" />
						<IconGear :width="30" :height="30" />
						<span class="hide-small">Settings</span>
					</router-link>
				</li>
				<li v-if="admin">
					<router-link :to="{name: 'AdminAffiliates'}">
						<span class="highlight hide-small" />
						<IconGear :width="30" :height="30" />
						<span class="hide-small">Affiliates</span>
					</router-link>
				</li>
				<li v-if="admin">
					<router-link :to="{name: 'AdminIntegrations'}">
						<span class="highlight hide-small" />
						<IconPlugin :width="30" :height="30" />
						<span class="hide-small">Integrations</span>
					</router-link>
				</li>
			</ul>
		</nav>
	</aside>
</template>

<script>
	import IconUsers from '../components/icons/IconUsers';
	import IconCourses from '../components/icons/IconCourses';
	import IconMenu from '../components/icons/IconMenu';
	import IconGear from '../components/icons/IconGear';
	import IconPlugin from '../components/icons/IconPlugin';
	import Store from '../inc/store';
	import sidebarMixin from '../mixins/sidebarMixin';
	import {isAdmin} from '../inc/auth';
	import IconStudentHat from './icons/IconStudentHat.vue';

	export default {
		components: {
			IconUsers,
			IconCourses,
			IconMenu,
			IconGear,
			IconPlugin,
			IconStudentHat
		},
		mixins: [sidebarMixin],
		computed: {
			classes() {
				return 'sidebar admin-sidebar' + (this.showMenu ? ' active' : '');
			},
			tenantName() {
				return Store.tenant.name;
			},
			admin() {
				return isAdmin();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.admin-sidebar {
		h2 {
			font-size: 1.25em;
			line-height: $line__height;
			text-align: center;
			margin-bottom: $sidebar__padding;
		}

		&:not(.active) {
			nav {
				position: relative;
				top: 100px;
				padding: 0;

				ul {
					padding: 0;

					svg {
						margin: 0;
					}
				}
			}

			.sidebar-content a {
				justify-content: center;
			}
		}
	}
</style>