import { render, staticRenderFns } from "./AdminSidebar.vue?vue&type=template&id=9f2e298e&scoped=true&"
import script from "./AdminSidebar.vue?vue&type=script&lang=js&"
export * from "./AdminSidebar.vue?vue&type=script&lang=js&"
import style0 from "./AdminSidebar.vue?vue&type=style&index=0&id=9f2e298e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f2e298e",
  null
  
)

export default component.exports